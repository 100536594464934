@import url('https://fonts.googleapis.com/css2?family=Protest+Revolution&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    width: 100%;
}


.active-sidebar {
    margin-left: -280px;
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh;
}

.active-cont {
    margin-left: 0;
}

.content {
    width: 83%;
    height: 100%;
}

.btnsidebar {
    visibility: hidden;
}

.sidebar {
    /* height: 100%; */
    width: 17%;
    background-color: rgb(43, 40, 62);
    color: white;
    /* max-height: 100%; */

}

.detailContent {
    background-color: #E6E5E6;
    width: 100%;
    height: calc(100vh - 40px);
    overflow-y: auto;
}

.offcanvas-xl {
    background-color: rgb(43, 40, 62);
    height: calc(100vh - 100px);
    overflow-y: auto;

}

@media (max-width: 1200px) {
    .sidebar {
        margin-left: -17%;
    }

    .offcanvas-xl {
        height: 100%;
    }

    .btnsidebar {
        visibility: visible;
    }

    .active-sidebar {
        margin-left: 0;
    }

    .content {
        width: 100%;
        height: 100vh;
        transition: all 0.3s ease-in-out;
    }
}

.itemSidebar {
    cursor: pointer;
    padding-left: 1rem;
    /* padding-right: 2rem */
    ;
    height: 3rem;
    padding-top: 10px;
}

.itemSidebar:hover {
    background-color: #c2cad2;
    color: black;
}

.titleAdmin {
    font-family: Protest Revolution;
    font-size: 60px;
    color: rgb(240, 162, 18)
}

/* react-quill */
.ql-toolbar {
    background-color: #C4D3FA;
}

th,
td {
    text-align: center;
}

/* placeholder div */
[contentEditable=true]:empty:before {
    content: attr(placeholder);
    opacity: 0.6;
}