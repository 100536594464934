@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Dancing+Script:wght@400..700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    width: 100%;
}

/* Header */
.header {
    position: sticky;
    top: 0;
    z-index: 3
}

.imgHomepage {
    width: 100%;
    height: 800px;
    /* Định nghĩa chiều cao mong muốn của phần tử chứa ảnh */
    background-image: url('https://websitedemos.net/budget-hotel-04/wp-content/uploads/sites/822/2021/06/hero-bg.jpg');
    background-size: cover;
    /* Hiển thị ảnh phủ đầy kích thước của phần tử */
    position: relative;
}

.comment {
    width: 100%;
    height: 500px;
    /* Định nghĩa chiều cao mong muốn của phần tử chứa ảnh */
    background-image: url('https://websitedemos.net/heritage-hotel-04/wp-content/uploads/sites/815/2021/04/heritage-hotel-hero-img-bg.jpg');
    position: relative;
}

.contact {
    width: 100%;
    height: 500px;
    background-image: url('https://websitedemos.net/budget-hotel-04/wp-content/uploads/sites/822/2021/06/footer-bg.jpg');
    background-size: cover;
    /* Đảm bảo ảnh cover hết phần nền */
    background-position: center;
    /* Căn giữa ảnh */
    background-repeat: no-repeat;
    /* Ngăn lặp lại ảnh */
    position: relative;
}

.bgimg {
    opacity: 0.3;
    background-image: linear-gradient(83.2deg, rgba(150, 93, 233, 1) 10.8%, rgba(99, 88, 238, 1) 94.3%);
}

.bgcontact {
    opacity: 0.5;
    background-color: rgb(0, 0, 0);
}

.bgcomment {
    opacity: 0.6;
    background: rgb(2, 0, 36);
    background: linear-gradient(146deg, rgba(2, 0, 36, 1) 0%, rgba(121, 205, 37, 1) 0%, rgba(0, 212, 255, 1) 100%);
}

.welcomeText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contactText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.divCommentDetail {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.listLocation {
    font-family: "Dancing Script", cursive;
    font-weight: bold;
    font-size: 40px;
    color: orange;
}

.textAboutUs {
    font-family: "Dancing Script", cursive;
    font-weight: bold;
    color: orange;
    font-size: 30px;
}

.textComment {
    font-family: "Dancing Script", cursive;
    font-weight: bold;
    /* color: orange; */
    font-size: 30px;
}

.nameLocation {
    position: absolute;
    height: 2rem;
    text-align: center;
    left: 0;
    background-color: rgb(223, 108, 41);
    color: white;
    /* border-radius: 10px; */

}

.price {
    position: absolute;
    width: 34%;
    height: 2rem;
    text-align: center;
    top: -4%;
    left: 2%;
    background-color: red;
    color: white;
    border-radius: 10px;
}

.sale {
    position: absolute;
    height: 2rem;
    text-align: center;
    top: -4%;
    right: 0;
    background-color: red;
    color: white;
    /* border-radius: 10px; */
}

.btnSeeAllRoom {
    background-color: transparent;
    border: 1px solid #4D3DDD;
    color: #4D3DDD;
    border-radius: 5px;
    height: 2rem;
    width: 5rem;
}

.btnSeeAllRoom:hover {
    background-color: #4D34DD;
    color: white;
}

.roomDescription {
    padding: 20px;
    width: 80%;
    height: auto;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);

    background-color: white;
}

.detailBlog {
    padding: 20px;
    width: 80%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    background-color: white;
}

.card-img-top:hover {

    transform: scale(1.2);
    /* Phóng to ảnh lên 120% khi hover */
}

.card-img-top {
    transition: all 0.3s ease-in;
}

.room {
    overflow: hidden;
}

.location {
    background-color: #F7F6FE;
}

.howToReach {
    background-color: #F7F6FE;
}

.emptyroom .blog {
    background-color: #F7F6FE;
}

.facilities {
    background-color: #F7F6FE;
}

.footer {
    background-color: #F7F6FE;
}

/* Comment */
.avatar {
    border-radius: 0% 100% 0% 100% / 61% 0% 100% 39%;
    background-color: orange;
    height: 5rem;
    width: 5rem;
}

/* footer */
.iconfooter {
    color: #4D34DD;
    margin-right: 15px;
}

/* End homepage */


/* Room */
.imgRoom {
    width: 100%;
    height: 400px;
    background-image: url('https://websitedemos.net/budget-hotel-04/wp-content/uploads/sites/822/2021/04/budget-hotel-rooms-header-img-bg.jpg');
    background-size: cover;
    position: relative;
}

.roomText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bgroom {
    opacity: 0.5;
    background-image: linear-gradient(83.2deg, rgba(150, 93, 233, 1) 10.8%, rgba(99, 88, 238, 1) 94.3%);
}

.allroom {
    background-color: #F7F6FE;
}

.hotelName {
    color: #4D34DD;
}

.cardRoom {
    background-color: #F7F6FE;
    width: 100%;
}

.iconConvinient {
    margin-right: 10px;
}

.iconInfo {
    margin-top: 5px;
    margin-right: 1rem;
    font-size: 25px;
    color: #4D34DD;
}

.priceRoom {
    font-weight: 600;
    color: red;
}

/* End Room */


/* Things to do */
.imgThinsToDo {
    width: 100%;
    height: 400px;
    background-image: url('../public/images/catba.png');
    background-size: cover;
    position: relative;
}

.thingToDoText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bgThings {
    opacity: 0.3;
    background-image: linear-gradient(83.2deg, rgba(150, 93, 233, 1) 10.8%, rgba(99, 88, 238, 1) 94.3%);
}

.detailLocation {
    font-size: 17px;
}

/* End */

/* Facilities */

.imgFacilities {
    width: 100%;
    height: 400px;
    background-image: url('https://websitedemos.net/budget-hotel-04/wp-content/uploads/sites/822/2021/04/budget-hotel-facilities-header-img-bg.jpg');
    background-size: cover;
    position: relative;
}

.FacilitiesText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bgFacilities {
    opacity: 0.5;
    background-image: linear-gradient(83.2deg, rgba(150, 93, 233, 1) 10.8%, rgba(99, 88, 238, 1) 94.3%);
}

.iconFacilities {
    font-size: 30px;
    color: #4D34DD;
}

/* End */

/* Contact */
.imgContact {
    width: 100%;
    height: 400px;
    background-image: url('https://websitedemos.net/budget-hotel-04/wp-content/uploads/sites/822/2021/04/budget-hotel-contact-header-img-bg.jpg');
    background-size: cover;
    position: relative;
}

/* .contactText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */
.bgContact {
    opacity: 0.4;
    background-image: linear-gradient(83.2deg, rgba(150, 93, 233, 1) 10.8%, rgba(99, 88, 238, 1) 94.3%);
}

/* .iconFacilities{
    font-size: 30px;
    color: #4D34DD;
} */
.contactUs {
    background-color: #F7F6FE;
}

.formContact {
    background-color: #F7F6FE;
}

/* End */

.inputContact {
    height: 4rem;
}

.inputContactBig {
    height: 9rem;
}

.btnsend {
    border: 1px solid #4D34DD;
    color: #4D34DD;
    height: 3rem;
    width: 10rem;
    background-color: transparent;
    border-radius: 5px;
    font-weight: 500;
}

/* Blog */
.imgBlog {
    width: 100%;
    height: 400px;
    background-image: url('https://websitedemos.net/budget-hotel-04/wp-content/uploads/sites/822/2021/04/budget-hotel-things-to-do-img-1.jpg');
    background-size: cover;
    position: relative;
}

.blogText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bgBlog {
    opacity: 0.5;
    background-image: linear-gradient(83.2deg, rgba(150, 93, 233, 1) 10.8%, rgba(99, 88, 238, 1) 94.3%);
}

.post:hover {
    background-color: #e3e2ea;
}

.post-image {
    max-height: 100px;
}


/* detail room */

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.divdetailRoom {
    animation: slideInFromLeft 0.7s ease-out forwards;
}

.divImageRoom {
    animation: slideInFromLeft 0.7s ease-out forwards;
}


.divRent {
    animation: slideInFromRight 0.7s ease-out forwards;
}

.divDetailRent {
    animation: slideInFromRight 0.7s ease-out forwards;
}

@media (min-width: 992px) {
    .divSearchBlog {
      position: sticky;
      top: 0; /* hoặc bất kỳ vị trí nào bạn muốn */
    }
  }
  